import React from 'react';
import { Text, TextMagazine } from '@/components/atoms';
import styled, { css } from 'styled-components';
import { useAppContext } from '@/context/state';
import { IParagraph, IParagraphFields } from '@/types/contentful/contentful';

const Container = styled.div<IContainer>`
  display: flex;
  ${props =>
    props.position === 'left' &&
    css`
      justify-content: start;
    `}
  ${props =>
    props.position === 'center' &&
    css`
      justify-content: center;
    `}
    ${props =>
    props.position === 'right' &&
    css`
      justify-content: end;
    `}
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 0px;
  }
  background-color: ${props =>
    props.background ? props.background : 'transparent'};

  ${props =>
    (props.background || props.background === 'transparent') &&
    css`
      padding: 48px 0;
      ${props => props.theme.breakpoints.up('lg')} {
        padding: 48px 0;
      }
    `}

  p:last-child {
    margin-bottom: 0;
  }
`;

interface IContainer {
  background?: string;
  marginBottom?: string;
  index?: number;
  position?: 'left' | 'right' | 'center';
}

interface IParagraphFieldsCmp extends IParagraphFields {
  index?: number;
  firstletter?: boolean;
}
interface IParagraphCmp extends IParagraph {
  fields: IParagraphFieldsCmp;
}

const Paragraph: React.FC<IParagraphCmp> = props => {
  const {
    background,
    paragraph,
    maxWidth,
    textAlign,
    index,
    firstletter,
    position,
    enableFont,
  } = props.fields;

  const context = useAppContext();
  const pageType = context?.pageType;
  let content = null;

  if (paragraph) {
    content =
      pageType === 'magazine' ? (
        <TextMagazine
          content={paragraph}
          maxWidth={maxWidth}
          textAlign={textAlign}
        />
      ) : (
        <Text
          content={paragraph}
          maxWidth={maxWidth}
          textAlign={textAlign}
          index={index}
          firstletter={firstletter}
          position={position}
          enableFont={enableFont}
        />
      );
  }

  return (
    <Container background={background} index={index} position={position}>
      {content}
    </Container>
  );
};

export default Paragraph;
