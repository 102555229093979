import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { Typography } from '@/components/atoms';
import styled, { css } from 'styled-components';
import createComponents from '@/utils/createComponents';

const Container = styled.div<IContainer>`
  text-align: ${props => (props.textAlign ? props.textAlign : '')};
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '1920px')};
  overflow-wrap: break-word;
  ${props =>
    props.firstletter &&
    css`
      p:first-child::first-letter {
        font-weight: bold;
        font-size: 80px;
      }
    `}

  p > a {
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
  }
  ul,
  ol {
    margin: auto;
    li {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0px;
      }
      p {
        padding: 0 0;
      }
    }
  }
  ul {
    list-style-type: disc;
  }
  ${props => props.theme.breakpoints.up('sm')} {
    ul,
    ol {
      list-style-position: outside;
    }
  }
`;

const CustomParagraph = styled(Typography)<ICustomParagraph>`
  margin: 0 auto 24px;
  text-align: ${props => (props.textalign ? props.textalign : '')};
  &:last-child {
    margin-bottom: 0px;
  }
`;

const CustomHeading = styled(Typography)<ICustomHeading>`
  margin-bottom: 16px;
  ${props =>
    props.position === 'right' &&
    css`
      text-align: right;
    `}
  &:last-child {
    margin-bottom: 0px;
  }
`;

const CustomBlockquote = styled.blockquote`
  text-align: center;
  margin: 0 auto 48px;
  font-size: 18px;
  display: block;
  p {
    color: inherit;
  }
`;

const EmbedContent = styled.div`
  margin-bottom: 64px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

interface ICustomHeading {
  position?: 'left' | 'right' | 'center';
}
interface ICustomParagraph {
  textalign?: string;
  subheading?: boolean;
}
interface IContainer {
  lineHeightDesktop?: string;
  lineHeightMobile?: string;
  textAlign?: string;
  maxWidth?: number;
  marginBottom?: string;
  firstletter?: boolean;
  position?: 'left' | 'right' | 'center';
}

interface IRichText {
  content: any;
  textAlign?: string;
  maxWidth?: number;
  index?: number;
  firstletter?: boolean;
  position?: 'left' | 'right' | 'center';
  enableFont?: boolean;
}

const Text: React.FC<IRichText> = props => {
  const {
    content,
    maxWidth,
    textAlign,
    index,
    firstletter,
    position,
    enableFont,
  } = props;

  const headingTag = index === 0 ? 'h1' : 'h2';

  const options: Options = {
    renderText: text =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return (
          children &&
          (children as any)[0][1] !== '' && (
            <CustomParagraph datatype="body_1">{children}</CustomParagraph>
          )
        );
      },
      [BLOCKS.HEADING_1]: (_, children) => (
        <CustomHeading
          component={headingTag}
          datatype="heading_1"
          position={position}
          className={enableFont ? 'titlebig' : ''}>
          {children}
        </CustomHeading>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
        <CustomHeading
          component={headingTag}
          datatype="heading_2"
          position={position}
          className={enableFont ? 'titlebig' : ''}>
          {children}
        </CustomHeading>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
        <CustomHeading
          component={headingTag}
          datatype="heading_3"
          position={position}
          className={enableFont ? 'titlebig' : ''}>
          {children}
        </CustomHeading>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
        <CustomHeading
          component={headingTag}
          datatype="heading_4"
          position={position}
          className={enableFont ? 'titlebig' : ''}>
          {children}
        </CustomHeading>
      ),
      [BLOCKS.HEADING_5]: (_, children) => (
        <CustomHeading
          component={headingTag}
          datatype="heading_5"
          position={position}
          className={enableFont ? 'titlebig' : ''}>
          {children}
        </CustomHeading>
      ),
      [BLOCKS.HEADING_6]: (_, children) => (
        <CustomParagraph component={'p'} datatype="subtitle_2">
          {children}
        </CustomParagraph>
      ),
      [BLOCKS.QUOTE]: (_, children) => (
        <CustomBlockquote>{children}</CustomBlockquote>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        let data = node.data.target;
        if (node.data.target.sys.contentType.sys.id === 'section') {
          data = node.data.target.fields.organism;
        }
        let rest = {};
        if (node.data.target?.sys?.contentType?.sys?.id === 'productCarousel') {
          rest = {
            id: node.data.target.sys?.id,
          };
        }
        const comp = createComponents(data, rest);
        return <EmbedContent>{comp}</EmbedContent>;
      },
    },
  };

  return (
    <Container
      maxWidth={maxWidth}
      textAlign={textAlign}
      firstletter={firstletter}>
      {documentToReactComponents(content, options)}
    </Container>
  );
};

export default Text;
