import React from 'react';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { Typography } from '@/components/atoms';
import styled, { css } from 'styled-components';
import createComponents from '@/utils/createComponents';
import { IProdCarousel } from '@/utils/Product';

const Container = styled.div<IContainer>`
  overflow-wrap: break-word;
  > p,
  > h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol {
    max-width: 1000px;
  }
  p > a {
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
  }
  ul,
  ol {
    margin: auto;
    padding: 0px 24px 0px 40px;
    li {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0px;
      }
      p {
        padding: 0 0;
      }
    }
  }
  ul {
    list-style-type: disc;
  }
  ${props => props.theme.breakpoints.up('sm')} {
    ul,
    ol {
      list-style-position: outside;
    }
  }
`;

const CustomParagraph = styled(Typography)<ICustomParagraph>`
  margin: 0 auto 40px;
  padding: 0 24px;
  ${props =>
    props.subheading &&
    css`
      text-align: center;
    `}

  u {
    color: ${props => props.theme.paletteDefault.primary.main};
  }
`;

const CustomHeading = styled(Typography)<ICustomHeading>`
  margin: 0 auto 16px;
  padding: 0 24px;
  font-weight: normal;
  font-size: 20px;
  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 24px;
  }
  b {
    font-weight: normal;
  }
`;

const CustomBlockquote = styled.blockquote<ICustomBlockquote>`
  text-align: center;
  margin: 0 auto 40px;
  display: block;
  p {
    color: inherit;
    font-size: 22px;
  }
  ${props => props.theme.breakpoints.up('sm')} {
    p {
      font-size: 22px;
      b {
        font-weight: normal;
      }
    }
  }
`;

interface ICustomBlockquote {
  textalign?: string;
}
interface ICustomHeading {}
interface ICustomParagraph {
  textalign?: string;

  subheading?: boolean;
}
interface IContainer {
  lineHeightDesktop?: string;
  lineHeightMobile?: string;
  textAlign?: string;
  maxWidth?: number;
  marginBottom?: string;
}

interface ITextMagazine {
  content: Document | null;
  textAlign?: string;
  maxWidth?: number;
  productCarousel?: IProdCarousel[];
}

const TextMagazine: React.FC<ITextMagazine> = props => {
  const { content, maxWidth, textAlign, productCarousel } = props;

  const options: Options = {
    renderText: text =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return <CustomParagraph datatype="body_1">{children}</CustomParagraph>;
      },
      [BLOCKS.HEADING_2]: (_, children) => (
        <CustomHeading component="h2" className="bigtitle">
          {children}
        </CustomHeading>
      ),
      [BLOCKS.QUOTE]: (_, children) => (
        <CustomBlockquote textalign={'center'}>{children}</CustomBlockquote>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        let data = node.data.target;
        if (node.data.target.sys.contentType.sys.id === 'section') {
          data = node.data.target.fields.organism;
        }
        let rest = {};
        if (node.data.target?.sys?.contentType?.sys?.id === 'productCarousel') {
          if (productCarousel && productCarousel.length > 0) {
            productCarousel.forEach(prd => {
              if (prd.id === node.data.target.sys?.id) {
                rest = {
                  id: node.data.target.sys?.id,
                  products: prd.data,
                };
              }
            });
          }
        }
        const comp = createComponents(data, rest);
        return comp && <div style={{ marginBottom: '64px' }}>{comp}</div>;
      },
    },
  };

  return content ? (
    <Container maxWidth={maxWidth} textAlign={textAlign}>
      {documentToReactComponents(content, options)}
    </Container>
  ) : null;
};

export default TextMagazine;
